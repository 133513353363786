import React, { useRef, useLayoutEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import { Container } from "react-bootstrap";
import { gsap } from "gsap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";

const Contact = ({ data }) => {
  if (!data) return null;
  const contact = data.page.data;
  const fireflys = 20;
  const contactRef = useRef();

  useLayoutEffect(() => {
    const contactCtx = gsap.context(() => {
      const tl = gsap.timeline();
      tl.from(".contact-title", {
        y: -50,
        opacity: 0,
        duration: 0.8,
        ease: "power1.out",
      });
      tl.from(
        ".contact-subtitle",
        {
          opacity: 0,
          duration: 0.6,
          ease: "power1.out",
        },
        "-=0.3"
      );
      contact.contact_info.forEach((info, i) => {
        tl.from(
          `.contact-info-${i}`,
          {
            y: 30,
            opacity: 0,
            duration: 0.7,
            ease: "power1.out",
          },
          "-=0.4"
        );
      });
    }, contactRef);
    return () => {
      contactCtx.revert();
    }; // animation cleanup
  }, []);

  return (
    <>
      <Seo page={data.page} />
      <Layout>
        <Container
          fluid
          className="contact-page d-flex flex-column justify-content-center ps-4 ps-sm-7 pe-4 pe-sm-7"
          ref={contactRef}
        >
          <h1 className="contact-title mb-4">{contact.title}</h1>
          <div className="contact-subtitle mb-4">
            <PrismicRichText field={contact.subtitle.richText} />
          </div>
          <div>
            {contact.contact_info.map((item, i) => {
              return (
                <div
                  key={item.contact_label}
                  className={`contact-info-${i} d-flex align-items-center mb-4`}
                >
                  <Anchor
                    href={item.contact_link.url}
                    target={item.contact_link.target}
                    className="d-flex align-items-center contact_link"
                  >
                    <GatsbyImage
                      image={item.contact_icon.gatsbyImageData}
                      alt={item.contact_icon.alt || "contact icon"}
                      className="contact-icon me-2"
                    />
                    <h5 className="m-0">{item.contact_label}</h5>
                  </Anchor>
                </div>
              );
            })}
          </div>
        </Container>
        {/* Fireflys effect */}
        {[...Array(fireflys)].map((firefly, i) => {
          // eslint-disable-next-line react/no-array-index-key
          return <div className="firefly" key={i} />;
        })}
      </Layout>
    </>
  );
};

export const query = graphql`
  query contactQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicContact(lang: { eq: $lang }) {
      alternate_languages {
        type
        lang
      }
      lang
      type
      url
      data {
        meta_description
        meta_title
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title
        subtitle {
          richText
        }
        contact_info {
          contact_label
          contact_link {
            url
            target
            link_type
          }
          contact_icon {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
      }
    }
  }
`;

export default Contact;
